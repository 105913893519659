import { render, staticRenderFns } from "./MemberRegister.vue?vue&type=template&id=047da2fd&scoped=true&"
import script from "./MemberRegister.vue?vue&type=script&lang=js&"
export * from "./MemberRegister.vue?vue&type=script&lang=js&"
import style0 from "./MemberRegister.vue?vue&type=style&index=0&id=047da2fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047da2fd",
  null
  
)

export default component.exports